.formContainer {
  padding: 64px;
  width: calc(50% - 128px);
  transition: all 0.2s ease-in-out;

  .error {
    color: #ff4d4f;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 2px;
    margin-top: -24px;
  }

  .title {
    padding: 16px 0;
    font-size: 32px;
  }

  .subtitle {
    padding: 16px 0;
  }

  .loginButton {
    width: 100%;
  }
}
