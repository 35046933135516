.root {
  padding-top: 20%;
  height: 100%;
}

.logout {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .icon {
    margin: 20px;
    cursor: pointer;
  }
}