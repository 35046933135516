.root {
  width: 100vw;
  height: 100vh;
  background-color: #F3F3F3FF;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.logo {
  align-items: center;
  display: flex;
  gap: 17px;
  font-size: 32px;
  font-weight: 500;
  color: #F3F3F3FF;
  cursor: pointer;
  user-select: none;
}

.userButton {
  padding: 6px;
  height: 44px;
  width: 44px;
  border-radius: 22px;
  font-size: 32px;
  color: #FFFFFF;

  transition: all 0.2s ease-in-out;

  &:hover {
    color: #001529;
    background-color:  #F3F3F3FF;
    cursor: pointer;
  }
}

.userMenu {
  margin-top: 16px;
}

.content {
  padding: 24px 24px 24px;
  overflow-x: hidden;
}
