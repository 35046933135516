.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #CCCAD8FF;
}

.container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;

  margin: 96px;
  max-width: 800px;
  height: 600px;
  border-radius: 16px;
  background-color: #FFFFFF;
}

.logoContainer {
  display: flex;

  width: 50%;
  height: 100%;
  background-color: #F1F2F7FF;
  border-radius: 0 16px 16px 0;

  .logo {
    width: 100%;
  }
}