.wrapper {
  padding: 10px 24px;

  :global(.ant-breadcrumb-separator) {
    color: white;
  }
  a {
    :hover {
      color: #ececec;
      background-color: #001529;
    }
    span {
      color: white;
      background-color: #001529;
      :hover {
        color: #6f6f6f;
        background-color: #001529;
      }
    }
  }

  .linkItem {
    color: white;
    cursor: pointer;
  }
}
